import { getTranslations } from 'helpers/translations';
import { ContentPage, Homepage } from 'models';
import { NextApiRequest, NextApiResponse } from 'next';
import safeJsonStringify from 'safe-json-stringify';
import slugify from 'slugify';
import { Translations } from 'store/translations';
import { hrefLink } from 'utils/normalization/hrefLink';
import { GetContentPage, getHomepage } from 'utils/sitemap';

const additionalSitemapPages = [
  '/contactUsUrl',
  '/contactUsUrl/mapUrl',
  '/contactUsUrl/peopleUrl',
  '/career/talentCommunitiesUrl',
  '/project',
  '/product',
  '/insight',
  '/news',
  '/career',
];

export const mainSubtype = {
  project: 'tagsSector',
  insight: 'tagsThemeTopic',
  product: 'tagsSector',
};
const categorized = Object.keys(mainSubtype);

export const normalize = (
  page: ContentPage,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
  type?: 'sitemap' | 'rss',
  useHrefLinkUtil = false,
  translations?: Translations,
) => {
  const mainSupertype = {
    office: translations ? `${translations?.multiple('contactUsUrl')}` : 'contact-us',
    people: translations
      ? `${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}`
      : 'contact-us/people',
    news: translations ? `${translations.multiple('news')}` : 'news',
  };
  const uncategorized = Object.keys(mainSupertype);
  //

  const codename = page.system?.codename;

  if (useHrefLinkUtil)
    return {
      [hrefLink(page, undefined, true, locale, translations).replaceAll(/\/+/g, '/')]: page.system.lastModified,
    };
  const category = page.elements?.category.value[0]?.codename;
  // eslint-disable-next-line no-console
  //console.log('category: ' + category);
  const supercategory =
    category && uncategorized.includes(category) && mainSupertype[category as keyof typeof mainSupertype];
  // eslint-disable-next-line no-console
  //console.log('supercategory: ' + supercategory);
  const subcategory =
    category &&
    categorized.includes(category) &&
    (page.elements[mainSubtype[category as keyof typeof mainSubtype]].value[0]?.codename as string);
  // eslint-disable-next-line no-console
  //console.log('subcategory: ' + subcategory);
  const subcategoryTranslated =
    subcategory &&
    (category.includes('project') || category.includes('product')
      ? translations?.sector(subcategory)
      : category.includes('insight')
      ? translations?.theme_topic(subcategory)
      : '');
  // eslint-disable-next-line no-console
  //console.log('subcategoryTranslated: ' + subcategoryTranslated);
  const slug = page.elements?.url.value;
  // eslint-disable-next-line no-console
  //console.log('slug: ' + slug);
  // full url for Content page
  const url =
    (locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${locale}`) +
    (slug &&
      (supercategory // if the page has supercategory
        ? subcategoryTranslated
          ? `/${supercategory}/${slugify(subcategoryTranslated, { lower: true })}`
          : `/${supercategory}` // check if there is also a subcategory. If yes, use both, otherwise only supercategory
        : '' + // and if there is no supercategory, add nothing to the url
            !supercategory && subcategoryTranslated
        ? `/${translations?.multiple(category).toLowerCase()}/${slugify(subcategoryTranslated, { lower: true })}`
        : '') + // then check if there is only a subcategory. If yes, map category to subcategory, otherwise add nothing
        `/${slug}`); // and then add slug
  // eslint-disable-next-line no-console
  //console.log('url: ' + url);
  if (type === 'sitemap') return { [url.replaceAll(/\/+/g, '/')]: page.system.lastModified };
  if (type === 'rss')
    return {
      url: url.replaceAll(/\/+/g, '/'),
      lastModified: page.system.lastModified,
      title: page.elements.summaryTitle.value,
      desciption: page.elements.summaryDescription.value,
    };
  return {
    [codename]: url.replaceAll(/\/+/g, '/'), //replace all underscores with single dash and remove duplicate slashes
  };
};

const sitemap = async (req: NextApiRequest, res: NextApiResponse) => {
  const locale = (req.query.locale as string) ?? req.cookies.NEXT_LOCALE ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const codename = req.query.codename;
  const slug = req.query.slug;
  const category = req.query.category;
  const isHomepage = req.query.homepage === 'true';
  const isSitemap = req.query.sitemap === 'true';
  const isRss = req.query.rss === 'true';
  const campaign = req.query.campaign;
  const isFullModel = req.query.modelOnly === 'true';
  const id = req.query.id;

  const translations = await getTranslations(locale);

  let pages = {} as ContentPage | ContentPage[] | Homepage | null | string;
  if (isHomepage) pages = (await getHomepage.model(locale)) as Homepage;
  else
    pages = await (codename
      ? GetContentPage.byCodename(locale, codename as string, true, undefined).then((response) =>
          response ? response.item : null,
        )
      : slug
      ? GetContentPage.bySlug(locale, slug as string, isFullModel, false)
      : id
      ? GetContentPage.byId(locale, id as string)
      : category
      ? GetContentPage.byCategory(locale, category as string)
      : campaign
      ? GetContentPage.byCampaign(locale, campaign as string)
      : isSitemap || isRss
      ? GetContentPage.all(locale)
      : []);

  const responseType = codename || slug || isHomepage ? 'page' : id ? 'string' : 'map';
  let response = {} as ReturnType<typeof normalize>[] | ContentPage | Homepage | null | string;
  if (codename || slug) response = pages as ContentPage;
  else if (id)
    response = Object.keys(normalize(pages as ContentPage, locale, undefined, true, translations))[0] as string;
  else if (isHomepage) response = pages as Homepage;
  else if (isSitemap) {
    // Add additional (overview) page to sitemap response
    const additionalPages = additionalSitemapPages.map((page) => {
      const date = new Date().toISOString();
      const pageArray = page.split('/');
      pageArray[0] = locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${locale}`;
      pageArray[1] = slugify(translations.multiple(pageArray[1]), { lower: true });
      if (pageArray[2]) pageArray[2] = slugify(translations.url(pageArray[2]), { lower: true });
      return { [pageArray.join('/')]: date };
    });
    response = (pages as ContentPage[])
      .map((page) => normalize(page, locale, 'sitemap', true, translations))
      .concat(additionalPages);
  } else if (isRss)
    response = (pages as ContentPage[])
      .map((page) => normalize(page, locale, 'rss', true, translations))
      .filter((page) => page);
  else response = (pages as ContentPage[]).map((page) => normalize(page, locale, undefined, false, translations));
  // Creates a codename: url object or sends the full model of the page

  // Add additional (overview) page to sitemap response

  res
    .status(200)
    .json(
      response &&
        (responseType === 'page'
          ? JSON.parse(safeJsonStringify(response as Object))
          : responseType === 'string'
          ? response
          : Object.assign({}, ...(response as ReturnType<typeof normalize>[]))),
    );
};

export default sitemap;
