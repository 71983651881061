
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'styles/globals.css';
import { Box, ChakraProvider } from '@chakra-ui/react';
// import { GetServerSideProps } from 'next';
// import { normalize } from './api/sitemap';
// import {SitemapProps} from './sitemap-as-urls'
// import { ContentPage } from 'models';
// import { Retryer } from 'react-query/types/core/retryer';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { BackLink } from 'components/base/Breadcrumb';
// import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin } from 'appInsights';
import LayoutDefault, { LayoutType } from 'components/layout/Default';
import { fetchNavData } from 'components/nav/NavFetching';
import { HeadingsCheck } from 'components/pages/HeadingsCheck';
import { geoCountries } from 'constants/geo-countries';
// import { config } from 'process';
// import { parseCookies } from 'nookies'; // Use the `nookies` library
// import { getLocale } from 'next-intl/server';
import { getTranslations } from 'helpers/translations';
import { GTM_ID, pageView } from 'lib/gtm';
import { languageMapper } from 'lib/languages';
import cacheData from 'memory-cache';
// import { Redirect } from 'models';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { ReactElement, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withTranslations } from 'store/translations';
import Fonts from 'styles/fonts';
import lightTheme from 'themes/lightTheme';
import { getHost } from 'utils/getHost';
import { setZustandTranslations } from 'utils/setZustandTranslations';
import { GetContentPage } from 'utils/sitemap';
export function App({ Component, pageProps }: AppProps): ReactElement {
    // const { Component, pageProps } = props;
    // const [hasReloaded, setHasReloaded] = useState(false);
    const router = useRouter();
    const locale: any = router.locale;
    const [cookie, setCookie] = useCookies();
    let translations = withTranslations();
    const [layout, setLayout] = useState<LayoutType>();
    const layoutCodenames = pageProps.layout ?? pageProps.pageProps?.layout;
    const hasFrontpageCarousel = pageProps?.homepage?.elements.content.value[0].includes('front_page_carousel');
    const hasHero = pageProps?.model?.elements?.hero?.linkedItems?.length;
    if (hasHero == 0) {
        const pageContent = pageProps?.model?.elements?.content?.linkedItems[0].elements.pageContent;
        const CheckImageBreaker = pageContent?.linkedItems[0]?.system.type == 'image_visual_breaker';
        if (CheckImageBreaker) {
            cacheData.put('hasVideoinHero', true);
        }
    }
    else {
        cacheData.put('hasVideoinHero', false);
    }
    if (typeof window !== 'undefined') {
        document.addEventListener('DOMContentLoaded', () => {
            //   const nextDataElement = document.getElementById('__NEXT_DATA__');
            console.log('DOM fully loaded and parsed');
            //   console.log("nextDataElement",nextDataElement);
        });
        window.addEventListener('load', () => console.log('page is fully loaded'));
    }
    useEffect(() => {
        // const dir = locale === 'en' ? 'rtl' : 'ltr';
        const config: string = locale == 'fi-fi'
            ? 'A90E5A35-E86F-4572-B58F-870FF783DC49'
            : locale == 'no-no'
                ? 'BAC0328B-F671-44AA-9760-0C27CDD5AA75'
                : locale == 'sv-se'
                    ? '729ED397-2603-4EE0-B1CB-915657F55448'
                    : locale == 'de-de'
                        ? 'C72D0144-D799-4133-AC7D-01AA90170BCF'
                        : '84D36E2A-4745-4249-AF28-016CBE76DF36';
        const language: string = locale == 'fi-fi' ? 'fi' : locale == 'no-no' ? 'no' : locale == 'sv-se' ? 'sv' : locale == 'de-de' ? 'de' : 'en';
        // document.querySelector("div")?.setAttribute("data-configuration-id", dir);
        document.getElementById('sr-plugins')?.setAttribute('data-configuration-id', config);
        document.getElementById('sr-plugins')?.setAttribute('data-lang', language);
        // document.querySelector("body")?.setAttribute("dir", dir);
    }, [locale]);
    // const appInsights = new ApplicationInsights({
    //   config: {
    //     connectionString:
    //       'InstrumentationKey=d3cd31c8-b3ef-4b22-8d7a-edccf8e844fa;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    //     /* ...Other Configuration Options... */
    //     enableRequestHeaderTracking: true,
    //   },
    // });
    // appInsights.loadAppInsights();
    // appInsights.trackPageView();
    // appInsights.trackTrace({
    //   message: `Page props - ${
    //     pageProps?.homepage ? pageProps?.homepage?.system.codename : pageProps?.model?.system.codename
    //   }`,
    // });
    // let camCountry  =[];
    // pageProps.layout is for content type pages, pageProps.pageProps is for overview type pages
    const variants = (pageProps.variants ?? pageProps.pageProps?.variants) as Awaited<ReturnType<typeof GetContentPage.allLocales>> | undefined;
    pageProps?.homepage ? cacheData.put('homepage', true) : cacheData.put('homepage', false);
    const updateLocale = (locale = process.env.NEXT_PUBLIC_NEXT_LOCALE!) => {
        // temp redirect for post
        if (router.pathname.includes('ramboll-wins-million-dollar-tender-for-the-north-sea-energy-island')) {
            locale = 'da-dk';
        }
        cacheData.put('language', locale);
        // console.log('locale - ', locale);
        // console.log('translation - ', translations.locale);
        setZustandTranslations(translations, locale);
    };
    const updateLayout = async () => {
        cacheData.put('language', router.locale);
        let url = `/api/layout?locale=${router.locale}`;
        if (layoutCodenames?.navigationCodename)
            url += `&navigationCodename=${layoutCodenames.navigationCodename}`;
        const isCampaign = pageProps?.model?.elements?.category.value[0].codename === 'campaign';
        const navigation = await fetchNavData(layoutCodenames?.navigationCodename, isCampaign, locale);
        // console.log('navigation in update layout ',navigation)
        translations = await getTranslations(locale);
        if (layoutCodenames?.footerCodename)
            url += `&footerCodename=${layoutCodenames.footerCodename}`;
        if (layoutCodenames?.partOfCampaignCodename)
            url += `&partOfCampaignCodename=${layoutCodenames.partOfCampaignCodename}`;
        url += `&isCampaign=${isCampaign}`;
        const layout = (await fetch(url).then((res) => res.json())) as LayoutType;
        layout.navigation.isCampaign = !!layoutCodenames?.navigationCodename;
        setLayout(layout);
    };
    useEffect(() => {
        router.events.on('routeChangeComplete', pageView);
        return () => {
            router.events.off('routeChangeComplete', pageView);
        };
    }, [router.events]);
    useEffect(() => {
        router.beforePopState(({ options }) => {
            updateLocale(options.locale as string);
            return true;
        });
    });
    useEffect(() => {
        //  console.log('_app locale ',router.locale,translations.locale,layoutCodenames)
        updateLayout();
    }, [router.locale, layoutCodenames, router.isReady, cookie.NEXT_LOCALE]);
    useEffect(() => {
        //router.reload()
        updateLocale(router.locale);
        //  router.beforePopState(({ options }) => {
        //   updateLocale(options.locale as string);
        //   return true;
        // });
    }, [router.locale]);
    useEffect(() => {
        updateLocale(cookie.NEXT_LOCALE);
    }, [cookie.NEXT_LOCALE]);
    // useEffect(() => {
    //   if (router.locale && !hasReloaded) {
    //     router.reload();
    //     setHasReloaded(true);
    //   }
    // }, [router.locale, hasReloaded]);
    useEffect(() => {
        // const isBrowser = navigator.userAgent.match(/chrome|chromium|crios|firefox|fxios|safari|opr|edg/i);
        // if ( !router.isReady) return;
        // google consent script
        function deleteCookie(cookieName, expirationTime = new Date(0)) {
            document.cookie = `${cookieName}=; expires=${expirationTime.toUTCString()}; path=/;`;
        }
        // google consent script
        const googleConsentScript = document.getElementById('GoogleConsentScript');
        if (!googleConsentScript) {
            // Delete any existing GoogleConsentScript cookie with default expiration time
            deleteCookie('GoogleConsentScript');
            const googleConsentScriptTag = document.createElement('script');
            googleConsentScriptTag.setAttribute('id', 'GoogleConsentScript');
            googleConsentScriptTag.setAttribute('data-cookieconsent', 'ignore');
            googleConsentScriptTag.innerHTML = `              
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("consent", "default", {
      ad_personalization: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "granted",
      wait_for_update: 500
    });
    gtag("set", "ads_data_redaction", true);
    gtag("set", "url_passthrough", true);
  `;
            googleConsentScriptTag.setAttribute('type', 'text/javascript');
            document.head.appendChild(googleConsentScriptTag);
        }
        // google tag manager script
        //     const gtmScript = document.getElementById('gtag-base');
        //     if (!gtmScript) {
        //       const gtmScriptTag = document.createElement('script');
        //       gtmScriptTag.setAttribute('id', 'gtag-base');
        //       gtmScriptTag.setAttribute('data-cookieconsent', 'ignore');
        //       gtmScriptTag.setAttribute('strategy', 'afterInteractive');
        //       gtmScriptTag.setAttribute('type', 'text/javascript');
        //       gtmScriptTag.innerHTML = `
        //        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        // })(window,document,'script','dataLayer','${GTM_ID}');
        //       `;
        //       document.head.appendChild(gtmScriptTag);
        //     }
        const host = typeof window !== 'undefined' ? getHost(window) : '';
        // cookiebot script
        const cookieBotFetchAPI = async () => {
            await fetch(`${host}/api/appCookieBotFetch?languageParameter=${router.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!}`).then(async (res) => {
                const response = await res.json();
                localStorage.setItem('DomainId', response.domainGroupId);
                const script = document.getElementById('Cookiebot');
                if (!script) {
                    const scriptTag = document.createElement('script');
                    scriptTag.setAttribute('id', 'Cookiebot');
                    scriptTag.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
                    scriptTag.setAttribute('data-cbid', response.domainGroupId);
                    scriptTag.setAttribute('data-blockingmode', 'auto');
                    scriptTag.setAttribute('type', 'text/javascript');
                    document.head.appendChild(scriptTag);
                }
                return () => {
                    document.getElementById('Cookiebot')?.remove();
                };
            });
        };
        cookieBotFetchAPI();
        if (!cookie.NEXT_LOCALE)
            setCookie('NEXT_LOCALE', router.locale);
    }, []);
    const shouldShowHierarchy = router.query.showHierarchy === 'true' && process.env.NEXT_PUBLIC_APP_ENV === 'development';
    const [documentContent, setDocumentContent] = useState<string>();
    useEffect(() => {
        setDocumentContent(document.getElementsByTagName('main')[0]?.innerHTML);
    }, [router.isReady]);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://subscriptions.smartrecruiters.com/widget/v1/sr-job-alerts.js?dcr_ci=Ramboll3';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [router]); // router prop or w/e
    return (<>
      {/* <AppInsightsContext.Provider value={reactPlugin}> */}
      <Head>
        <link rel='preload' href='/fonts/B091DEC0FA8ECB331.woff2' as='font' type='font/woff2' crossOrigin='true'/>
        <link rel='preload' href='/fonts/8A3BF724BD34E9110.woff2' as='font' type='font/woff2' crossOrigin='true'/>
        <link rel='preload' href='/fonts/8F568C51C35302813.woff2' as='font' type='font/woff2' crossOrigin='true'/>
        <link rel='preload' href='/fonts/FE95703437E135722.woff' as='font' type='font/woff' crossOrigin='true'/>
        <link rel='preload' href='/public/fonts/Raleway-VariableFont_wght.ttf' as='font' type='font/ttf' crossOrigin='true'/>

        <link rel='preconnect' href='https://ramboll.piwik.pro'/>
        <link rel='preconnect' href='https://ramboll.containers.piwik.pro'/>

        {variants &&
            geoCountries.map((country) => {
                const slug = variants[languageMapper[country.hreflang]];
                return (<link key={country.regionCode} rel='alternate' hrefLang={country.hreflang} href={`${getHost()}${country.locale !== process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? `/${country.locale}` : ''}${slug}`}/>);
            })}
      </Head>

      <ChakraProvider theme={lightTheme}>
        <div id='sr-plugins' data-configuration-id={router.locale == 'fi-fi'
            ? process.env.JOB_ALERT_CONFIGURATION_ID_FI
            : router.locale == 'no-no'
                ? process.env.JOB_ALERT_CONFIGURATION_ID_NO
                : router.locale == 'sv-se'
                    ? process.env.JOB_ALERT_CONFIGURATION_ID_SV
                    : router.locale == 'de-de'
                        ? process.env.JOB_ALERT_CONFIGURATION_ID_DE
                        : process.env.JOB_ALERT_CONFIGURATION_ID_DEFAULT} data-bg='009DF0' data-lang={router.locale == 'fi-fi'
            ? process.env.JOB_ALERT_DATA_LANG_FI
            : router.locale == 'no-no'
                ? process.env.JOB_ALERT_DATA_LANG_NO
                : router.locale == 'sv-se'
                    ? process.env.JOB_ALERT_DATA_LANG_SV
                    : router.locale == 'de-de'
                        ? process.env.JOB_ALERT_DATA_LANG_DE
                        : process.env.JOB_ALERT_DATA_LANG_DEFAULT} data-company-identifier='Ramboll3'></div>
        <script>
          {`function(d, s, id) {
 var js, fjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id)) return;
 js = d.createElement(s); js.id = id;
 js.src = https://subscriptions.smartrecruiters.com/widget/v1/sr-job-alerts.js?dcr_ci=Ramboll3;
 fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'sr-jsplugins'))`}
        </script>

        <Script defer strategy='lazyOnload' id='gtm' dangerouslySetInnerHTML={{
            __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
console.log('Loaded GTM!');
})(window,document,'script','dataLayer','${GTM_ID}');
      `,
        }}/>

        <Script nonce={cookie['nonce']} id='KontentAiCustomElement' src='https://app.kontent.ai/js-api/custom-element/v1/custom-element.min.js'/>
        <Fonts />
        <Box display={shouldShowHierarchy ? 'none' : ''}>
          <LayoutDefault navMenu={layout?.navigation} footer={layout?.footer} hasFrontpageCarousel={hasFrontpageCarousel} variants={variants}>
            <Component {...pageProps}/>
          </LayoutDefault>
        </Box>
        {documentContent && shouldShowHierarchy && <HeadingsCheck docuementContent={documentContent}/>}
      </ChakraProvider>
      {/* </AppInsightsContext.Provider> */}
    </>);
}
const __Next_Translate__Page__194927a10ae__ = App;

    export default __appWithI18n(__Next_Translate__Page__194927a10ae__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  