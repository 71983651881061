import { getTranslations } from 'helpers/translations';
import { ContentPage } from 'models';
import { normalize } from 'pages/api/sitemap';

import { GetContentPage } from './sitemap';

type BodyLinkObject = {
  linkId: string;
  codename: string;
  type: string;
  urlFull: string;
  urlSlug?: string;
};

export const getContentLinks = async (linkedItems: any, locale?: string) => {
  const bodyLinkArr: BodyLinkObject[] = [];
  if (
    linkedItems &&
    typeof linkedItems === 'object' &&
    Object.keys(linkedItems).length > 0 &&
    !Array.isArray(linkedItems)
  ) {
    Object.keys(linkedItems)
      .filter((linkKey) => linkedItems[linkKey].system?.type === 'text')
      .forEach((itemKey) => {
        const matchedTextModule = linkedItems[itemKey];
        const linksArr = matchedTextModule.elements?.body?.links;
        if (linksArr && Array.isArray(linksArr)) {
          bodyLinkArr.push(...linksArr);
        }
      });
    //.map(res=>{ console.log("results are:", res)});
  }

  const linksArr: any = await Promise.allSettled(
    bodyLinkArr.map(async (linkObj) => {
      const contentPage = await GetContentPage.byId(locale, linkObj.linkId);
      //const resp = await fetch(`/api/sitemap?id=${linkObj.linkId}&locale=${locale}`);
      //const url = await resp.json();
      const translations = await getTranslations(locale);
      const url = Object.keys(
        normalize(contentPage as ContentPage, locale, undefined, true, translations),
      )[0] as string;
      return {
        ...linkObj,
        urlFull: url,
      };
    }),
  );
  const successResults = linksArr.filter((promise) => promise.status === 'fulfilled');
  linksArr
    .filter((promise) => promise.status === 'rejected')
    .forEach((err) => {
      console.error(err);
    });

  return successResults.map((item) => item.value);
};
