import cacheData from 'memory-cache';
import { apiElements } from 'utils/sitemap';

import { ProdClient } from '../../client/client';
import {
  ContentPage,
  ContentPageActor,
  NavigationCategory,
  NavigationItem,
  NavigationLanguageItem,
  Search,
} from '../../models';
import { Navigation } from '../../models/content-types/navigation';
import { NavProps } from './NavBar';

const DEPTH_PARAMETER = 3;

const removeSummaryImagesFromNavigationSubpages = (item: NavigationItem) => {
  return (item as NavigationItem).elements.subpages.linkedItems.map((item) => {
    switch (item.system.type) {
      case 'content_page': {
        const { summaryImageFrontify: _, ...rest } = (item as ContentPage).elements;
        if ((item as ContentPage).elements.partOfCampaignPage.linkedItems[0]) {
          const { url } = (item as ContentPage).elements.partOfCampaignPage.linkedItems[0].elements;
          //@ts-ignore
          (item as ContentPage).elements.partOfCampaignPage.linkedItems[0].elements = { url };
        }
        return { elements: rest, system: item.system } as ContentPage;
      }
      case 'content_page_actor': {
        const { summaryImageFrontify: _, ...rest } = (item as ContentPageActor).elements;
        return { elements: rest, system: item.system } as ContentPageActor;
      }
      case 'navigation_category': {
        const { elements, system } = item as NavigationCategory;
        const subpages = elements.linkedPages.linkedItems.map((item) => {
          const { summaryImageFrontify: _, ...rest } = (item as ContentPage | ContentPageActor).elements;
          return { elements: rest, system: item.system } as ContentPage | ContentPageActor;
        });
        return {
          elements: { ...elements, linkedPages: { ...elements.linkedPages, linkedItems: subpages } },
          system,
        } as NavigationCategory;
      }
      default: {
        return item as NavigationLanguageItem;
      }
    }
  });
};

const getNavProps = async (campaign = false, locale: string, codename?: string) => {
  const additionalNavElements = [
    'navigation_titles',
    'highlighted_article',
    'subpages',
    'logo',
    'show_language_selector',
    'language_switcher',
    'title',
    'language_codename',
    'link_to_overview',
    'link_to_overview_page',
    'description',
    'linked_pages',
    'internal_cta_link',
    'cta_text',
    'summary__image_frontify',
    'for_campaign',
    'featured',
  ];

  if (cacheData.get(`navigation${locale}`) == null) {
    const response_data = await ProdClient.items<Navigation>()
      .type('navigation')
      .languageParameter(locale)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .depthParameter(DEPTH_PARAMETER)
      .elementsParameter(apiElements.concat(additionalNavElements))
      .toPromise();
    cacheData.put(`navigation${locale}`, response_data.data.items, 86400000, function () {
      console.log('clearing the cache');
      cacheData.del(`navigation${locale}`); //to clear the existing cache after 24hr
    });
  }
  const tempNavData = cacheData.get(`navigation${locale}`);
  let responseSchema;
  if (codename) {
    if (cacheData.get(`navigation-${codename}-${locale}`) == null) {
      const response = await ProdClient.item<Navigation>(codename)
        .languageParameter(locale)
        .depthParameter(DEPTH_PARAMETER)
        .toPromise();
      cacheData.put(`navigation-${codename}-${locale}`, response.data.item, 86400000, function () {
        console.log('clearing the cache');
        cacheData.del(`navigation-${codename}-${locale}`); //to clear the existing cache after 24hr
      });
      responseSchema = response.data.item;
    } else {
      responseSchema = cacheData.get(`navigation-${codename}-${locale}`);
    }
  }

  if (campaign && codename == undefined) {
    tempNavData.map((item) => {
      if (item.elements.forCampaign.value.length > 0 && item.elements.forCampaign.value[0].codename == 'yes')
        responseSchema = item;
    });
  }
  if (codename == undefined && !campaign) {
    tempNavData.map((item) => {
      if (item.elements.forCampaign.value.length == 0 && item.system.codename == 'navigation') responseSchema = item;
    });
  }
  return responseSchema;
};

export const fetchNavData = async (
  codename?: string,
  campaign = false,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
): Promise<NavProps> => {
  // Initially fetching the full navigation item
  const navProps = await getNavProps(campaign, locale, codename);
  const megaMenuProps = navProps.elements.navigationTitles.linkedItems
    .filter((item) => item.system.type === 'navigation_item')
    .map((item) => {
      const hasHighlightedArticle = (item as NavigationItem).elements.highlightedArticle.linkedItems.length;
      return {
        subpages: removeSummaryImagesFromNavigationSubpages(item as NavigationItem),
        highlightedArticle: hasHighlightedArticle
          ? (item as NavigationItem).elements.highlightedArticle.linkedItems[0]
          : null,
        itemCodename: item.system.codename,
      };
    });
  const languageSelectors = megaMenuProps
    .map((item) => item.subpages.filter((languageItem) => languageItem.system.type === 'navigation_language_item'))
    .flat() as NavigationLanguageItem[];
  const mainSearch =
    cacheData.get('search') == null
      ? await ProdClient.items<Search>()
          .type('search')
          .languageParameter(locale)
          .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
          .toPromise()
          .then((response) =>
            cacheData.put('search', response.data.items[0], 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('search'); //to clear the existing cache after 24hr
            }),
          )
      : cacheData.get('search');

  // const { navigationTitles } = navProps.elements;
  // navigationTitles.linkedItems.forEach((item) => {
  //   if (item.system.type === 'navigation_item') {
  //     const {
  //       subpages: _subpages,
  //       highlightedArticle: _highlightedArticle,
  //       ...rest
  //     } = (item as NavigationItem).elements;
  //     item.elements = rest as typeof item.elements;
  //   }
  // });

  return {
    languageSelectors,
    mainSearch,
    megaMenuProps: megaMenuProps,
    navProps,
    isCampaign: campaign,
  };
};
