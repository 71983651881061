import { NextApiRequest, NextApiResponse } from 'next';
import { GetContentPage } from 'utils/sitemap';

export const getLocaleVariants = async (
  slug: string,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
  isAll = false,
) => {
  const page = await GetContentPage.bySlug(locale, slug, undefined, false);
  const response = page && (!isAll ? page.elements.url.value : await GetContentPage.allLocales(page.system.codename));
  return response;
};

const localeVersionOfSlug = async (req: NextApiRequest, res: NextApiResponse) => {
  const slug = req.query.slug as string;
  const locale = (req.query.locale as string) ?? req.cookies.NEXT_LOCALE ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const isAll = req.query.all === 'true';

  const response = await getLocaleVariants(slug, locale, isAll);
  res.status(200).json(response);
};

export default localeVersionOfSlug;
