import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  StackItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { BodyWrapper } from 'components/wrapper/bodyWrapper';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';

import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage, FAQ as FAQModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Link } from '../base/Link';
import { htmlResolver } from 'helpers/htmlResolver';

export const FAQ: FC<{ model: FAQModel }> = ({ model }) => {
  const router = useRouter();
  const translations = withTranslations();
  const [items, setItems] = useState<
    {
      ctaText?: string | undefined;
      ctaUrl?: any;
      ctaOpenInNewTab?: boolean | undefined;
      headline: string;
      bodyHtml: string;
    }[]
  >();
 // const titleleft = model.elements.title?.value;
  const descleft = model.elements.description?.value;
  const titleleft = htmlResolver(model.elements.title)

  const description = htmlResolver(model.elements.description)
  //console.log('FAQ module ',model.elements.title?.value)
  const fetchItems = async () => {
    const items = await Promise.all(
      model.elements.items.linkedItems.map(async (item) => {
        const externalUrl = item.elements.ctaSnippetUrl.value;
        const kontentAiItem = item.elements.ctaSnippetKontentAiUrl.value[0];
        const fileFrontify = JSON.parse(item.elements.ctaSnippetFrontifyFile.value);
        const setUrl = async () => {
          if (fileFrontify) return fileFrontify.downloadUrl as string;
          if (externalUrl) return externalUrl;
          if (kontentAiItem) {
            const item = (await fetch(`/api/sitemap?codename=${kontentAiItem}&locale=${router.locale}`)
              .then((res) => res.json())
              .catch(() => null)) as ContentPage | null;
            return item ? Object.keys(normalize(item, router.locale, undefined, true, translations))?.[0] : '';
          }
        };
        return {
          headline: item.elements.headline.value,
          bodyHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: item.elements.body,
            urlResolver: (linkId, linkText, link) => {
              return {
                linkHtml: `<a class="xLink" href="/${link?.urlSlug}">${linkText}</a>`,
              };
            },
          }).html,
          ...((externalUrl || fileFrontify || kontentAiItem) && {
            ctaText: item.elements.ctaSnippetCtaText.value,
            ctaUrl: await setUrl(),
            ctaOpenInNewTab: item.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
          }),
        };
      }),
    );
    setItems(items);
  };

  useEffect(() => {
    fetchItems();
  }, []);
  useEffect(() => {
    fetchItems();
  }, [router.locale]);
  const data = {
    headline: model.elements.headline.value,
    items,
  };
  return (
    <>
      {titleleft != null ? (
        <Box maxW='1440px' pt='18px'>
          <Box
            w='100%'
            // pl= '80px'
            // pr='80px'
            //pt='117px'
            //  pt={{ base: '80px', md: '117px' }}
            gap={'10px'}
            display={'flex'}
            //  bgColor={textbg == 'full' ? textbgColor : 'white'}
            flexDirection={{ base: 'column', md: 'row' }}
            //alignItems="flex-start" justifyItems="space-between"
          >
            <Box
              w={{ base: '100%', md: '45%' }}
              //bg={textbgColor}
              //   pl='80px'
              pl={{ base: '20px', md: '80px' }}
              pr={{ base: '20px', md: '80px' }}
              pt={{ base: '80px', md: '122px' }}
              pb={{ base: '0px', md: '122px' }}
              // pr='80px'
            >
              <Text
                w={{ base: '100%', md: '300%' }}
                color={'#273943'}
                /* Desktop/H3 */
                textStyle={'desktop.h3'}
                pb='51px'
              >
                {titleleft?.map((text, index) => (
          <BodyWrapper key={index} sx={index ? { pt: 'xxs' } : {}} tag={text.tag} body={text.body} />
        ))}
              </Text>
              {/* <Box>{<BodyWrapper tag={descleft} body={descleft} />}</Box> */}
              <Box textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}>{description?.map((text, index) => (
          <BodyWrapper key={index} sx={index ? { pt: 'xxs' } : {}} tag={text.tag} body={text.body} />
        ))}</Box>
            </Box>
            <Box
              w={{ base: '100%', md: '55%' }}
              //  bgColor={textbg == 'full' ? textbgColor : 'white'}
              pt={{ base: '80px', md: '162px' }}
              pl={{ base: '20px', md: '20px' }}
              pr={{ base: '20px', md: '20px' }}
            >
              <VStack my={{ base: 's', md: 'l' }}>
                {/* <StackItem as='h2' textStyle={{ base: 'mobile.h3', md: 'desktop.h3' }} color='cyan.ramboll' mb='m'>
          {data.headline}
        </StackItem> */}
                <Accordion allowToggle w='100%' flexDirection={'column'}>
                  {data.items?.map((item, index) => (
                    <AccordionItem
                      key={index}
                      borderTop={'1px solid transparent'}
                      borderBottom={'1px solid'}
                      borderBottomColor={'mountain.20'}
                      pb='xs'
                      pt={index && 's'}
                      w='100%'
                    >
                      {({ isExpanded }) => {
                        return (
                          <>
                            <AccordionButton
                              textAlign='start'
                              as={'button'}
                              _hover={{ cursor: 'pointer' }}
                              justifyContent='space-between'
                              p='0'
                              textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.book.l' }}
                              w='100%'
                            >
                              {item.headline}
                              <Flex ml='xs'>
                                <IconComp styleName='active' iconName={isExpanded ? 'minus' : 'plus'} />
                              </Flex>
                            </AccordionButton>
                            <AccordionPanel textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.l' }} p='0'>
                              <Flex
                                mt='xs'
                                flexDir='column'
                                gap='xs'
                                sx={{
                                  a: {
                                    color: 'cyan.ramboll',
                                    textDecor: 'underline',
                                  },
                                  'ul, ol': {
                                    listStylePosition: 'inside',
                                  },
                                }}
                                dangerouslySetInnerHTML={{ __html: item.bodyHtml }}
                              />
                              {item.ctaUrl && (
                                <Box mt='xs'>
                                  <Link
                                    type={LinkType.SecondaryButton}
                                    href={item.ctaUrl}
                                    isExternal={item.ctaOpenInNewTab}
                                    iconName='chevronRightForLink'
                                    locale={router.locale}
                                  >
                                    {item.ctaText}
                                  </Link>
                                </Box>
                              )}
                            </AccordionPanel>
                          </>
                        );
                      }}
                    </AccordionItem>
                  ))}
                </Accordion>
              </VStack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box w={{ base: '100vw', '1md': '780px' }} px={{ base: 'xs', '1md': '0px' }} id={model.system.codename}>
          <VStack my={{ base: 's', md: 'l' }}>
            <StackItem as='h2' textStyle={{ base: 'mobile.h3', md: 'desktop.h3' }} color='cyan.ramboll' mb='m'>
              {data.headline}
            </StackItem>
            <Accordion allowToggle w='100%' flexDirection={'column'}>
              {data.items?.map((item, index) => (
                <AccordionItem
                  key={index}
                  borderTop={'1px solid transparent'}
                  borderBottom={'1px solid'}
                  borderBottomColor={'mountain.20'}
                  pb='xs'
                  pt={index && 's'}
                  w='100%'
                >
                  {({ isExpanded }) => {
                    return (
                      <>
                        <AccordionButton
                          textAlign='start'
                          as={'button'}
                          _hover={{ cursor: 'pointer' }}
                          justifyContent='space-between'
                          p='0'
                          textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.book.l' }}
                          w='100%'
                        >
                          {item.headline}
                          <Flex ml='xs'>
                            <IconComp styleName='active' iconName={isExpanded ? 'minus' : 'plus'} />
                          </Flex>
                        </AccordionButton>
                        <AccordionPanel textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.l' }} p='0'>
                          <Flex
                            mt='xs'
                            flexDir='column'
                            gap='xs'
                            sx={{
                              a: {
                                color: 'cyan.ramboll',
                                textDecor: 'underline',
                              },
                              'ul, ol': {
                                listStylePosition: 'inside',
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: item.bodyHtml }}
                          />
                          {item.ctaUrl && (
                            <Box mt='xs'>
                              <Link
                                type={LinkType.SecondaryButton}
                                href={item.ctaUrl}
                                isExternal={item.ctaOpenInNewTab}
                                iconName='chevronRightForLink'
                                locale={router.locale}
                              >
                                {item.ctaText}
                              </Link>
                            </Box>
                          )}
                        </AccordionPanel>
                      </>
                    );
                  }}
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>
        </Box>
      )}
    </>
  );
};
