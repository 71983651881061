import { hyphenateSync as hyphenateDk } from 'hyphen/da';
import { hyphenateSync as hyphenateDe } from 'hyphen/de-1996';
import { hyphenateSync as hyphenateEn } from 'hyphen/en-gb';
import { hyphenateSync as hyphenateFi } from 'hyphen/fi';
import { hyphenateSync as hyphenateNo } from 'hyphen/no';
import { hyphenateSync as hyphenateSv } from 'hyphen/sv';
import { useState } from 'react';

export const HyphenResult = (content, locale) => {
  //const [hyphenContent, setHyphenContent] = useState('');
  let hyphenContent = '';

  switch (locale) {
    case 'en': {
      hyphenContent = hyphenateEn(content);
      break;
    }
    case 'en-gb': {
      hyphenContent = hyphenateEn(content);
      break;
    }
    case 'en-us': {
      hyphenContent = hyphenateEn(content);
      break;
    }
    case 'en-apac': {
      hyphenContent = hyphenateEn(content);
      break;
    }
    case 'da-dk': {
      hyphenContent = hyphenateDk(content);
      break;
    }
    case 'de-de': {
      hyphenContent = hyphenateDe(content);
      break;
    }

    case 'sv-se': {
      hyphenContent = hyphenateSv(content);
      break;
    }
    case 'fi-fi': {
      hyphenContent = hyphenateFi(content);
      break;
    }
    case 'no-no': {
      hyphenContent = hyphenateNo(content);
      break;
    }

    default: {
      hyphenContent = hyphenateEn(content);
      break;
    }
  }
  return hyphenContent;
};
