import { ProdClient } from 'client/client';
import { getTranslations } from 'helpers/translations';
import { distinctLangaugeVariants } from 'lib/locales/languages';
import { forEach } from 'lodash';
import { ContentPage, Homepage } from 'models';
import { normalize } from 'pages/api/sitemap';

const DEPTH_PARAMETER = 4;

export type SitemapDataWithCampaigns = {
  campaignUrl: string;
  urls: ReturnType<typeof normalize>[];
}[];

export type SitemapData = ReturnType<typeof normalize>[];

export class SitemapService {
  private _linkedItems: any;
  private _sitemap: any;

  public constructor(public data) {
    this._linkedItems = data.linkedItems;
    this._sitemap = {};
    this._generateSitemap(data.item, this._linkedItems, []);
  }

  private _generateSitemapItem(item: any, content: any, urls: any) {
    let url;
    if (item.system.type == 'homepage') {
      url = [];
    } else if (!item.elements.url) {
      if (item.elements.category?.value[0].codename) url = item.elements.category.value[0].codename;
      else if (item.elements.tag?.value[0].codename) url = item.elements.tag.value[0].codename;
      else url = item.elements.service?.value[0].codename;
    } else {
      url = item.elements.url.value;
    }

    const slug = [...urls, url];

    this._sitemap[`${slug.join('/')}`] = {
      slug: `${slug.join('/')}`,
      codename: item.system.codename,
      type: item.system.type,
      excludeFromSitemap: item.elements.metadataExcludeFromSitemapXml?.value[0]?.codename == 'yes',
      lastModified: item.system.lastModified,
    };

    if (item.elements.subpages) {
      item.elements.subpages.value.map((subitem) => {
        this._generateSitemap(content[subitem], content, slug);
      });
    }
    return;
  }
  private _generateSitemap(homepage, items, urls) {
    this._generateSitemapItem(homepage, items, urls);
    // console.log('item.element.category : ' + items.element.category?.value[0]);
  }
  public getContentBySlug(slug) {
    return slug && this._linkedItems[this._sitemap[slug].codename];
  }
  public getSitemapXML(host) {
    let items = '';
    forEach(this._sitemap, (element) => {
      if (!element.excludeFromSitemap) {
        const date = new Date(element.lastModified);
        const displayDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        items += `
            <url>
              <loc>${`${host}${element.slug}`}</loc>
              <lastmod>${displayDate}</lastmod>
              <changefreq>weekly</changefreq>
              <priority>0.9</priority>
            </url>
          `;
      }
    });
    return `
      <xml version="1.0" encoding="UTF-8">
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
       ${items}
      </urlset>
      </xml>
   `;
  }
}

export const getSitemapXML = (protocol: string, host: string, sitemapData: SitemapDataWithCampaigns | SitemapData) => {
  let items = '';

  //Remove logic after launching the full site
  if (process.env.ENABLE_REDIRECTS === 'false') {
    Object.keys(sitemapData).forEach((key) => {
      const date = new Date(sitemapData[key]).toISOString();
      // Until DNS is completly taken over, this is the hotfix for sitemap
      const finalHost = host.includes('ramcmswebsiteprod') ? 'www.ramboll.com' : host;

      if (
        key.match('/products/') ||
        key.match('/projects/') ||
        key.match('/insights/') ||
        key.match('/news/') ||
        key.match('/annual-report-2022/') ||
        key.match('/annual-report-2022')
      ) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/products') || key.match('/projects') || key.match('/insights')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (
        key.match('/projekter/') ||
        key.match('/produkter/') ||
        key.match('/indsigter/') ||
        key.match('/nyheder/')
      ) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/projekter') || key.match('/produkter') || key.match('/indsigter')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (
        key.match('/projekte/') ||
        key.match('/Produkte/') ||
        key.match('/Insights/') ||
        key.match('/uutiset/')
      ) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/projekte') || key.match('/Produkte') || key.match('/Insights')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (
        key.match('/projektit/') ||
        key.match('/tuotteet/') ||
        key.match('/artikkelit/') ||
        key.match('/nyheter/')
      ) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/projektit') || key.match('/tuotteet') || key.match('/artikkelit')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (key.match('/prosjekt/') || key.match('/produkt/') || key.match('/innsikt/')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/prosjekt') || key.match('/produkt') || key.match('/innsikt')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (key.match('/projekt/') || key.match('/produkter/') || key.match('/insikter/')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.4</priority>
              </url>
            `;
      } else if (key.match('/projekt') || key.match('/produkter') || key.match('/insikter')) {
        items += `
              <url>
                <loc>${`${protocol}${finalHost}${key}`}</loc>
                <lastmod>${date}</lastmod>
                <changefreq>weekly</changefreq>
                <priority>0.9</priority>
              </url>
            `;
      } else if (key.match('/news')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.7</priority>
        </url>
      `;
      } else if (key.match('/nyheder')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.7</priority>
        </url>
      `;
      } else if (key.match('/uutiset')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.7</priority>
        </url>
      `;
      } else if (key.match('/nyheter')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.7</priority>
        </url>
      `;
      } else if (key.match('/karriere')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.9</priority>
        </url>
      `;
      } else if (key.match('/contact-us')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>weekly</changefreq>
          <priority>0.8</priority>
        </url>
      `;
      } else if (key.match('/ota-yhteytta')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>weekly</changefreq>
          <priority>0.8</priority>
        </url>
      `;
      } else if (key.match('/kontact-os') || key.match('/kontakt-oss') || key.match('/kontakta-ss')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>weekly</changefreq>
          <priority>0.9</priority>
        </url>
      `;
      } else if (key.match('/kontakt-aufnehmen')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>weekly</changefreq>
          <priority>0.8</priority>
        </url>
      `;
      } else if (key.match('/careers')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.9</priority>
        </url>
      `;
      } else if (key.match('/ura') || key.match('/karriär') || key.match('/karriar')) {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>daily</changefreq>
          <priority>0.9</priority>
        </url>
      `;
      } else {
        items += `
        <url>
          <loc>${`${protocol}${finalHost}${key}`}</loc>
          <lastmod>${date}</lastmod>
          <changefreq>weekly</changefreq>
          <priority>0.7</priority>
        </url>
      `;
      }
    });
  } else {
    sitemapData.forEach((campaign) => {
      Object.keys(campaign.urls).forEach((key) => {
        const date = new Date(sitemapData[key]).toISOString();
        //TODO: REMOVE CAMPAIGN LOGIC BEFORE LAUNCHING FULL SITE (use ENTIRE key instead of newUrl)
        const finalHost = process.env.ENABLE_REDIRECTS === 'true' ? process.env.CAMPAIGN_PRELAUNCH_URL : host;

        const newUrl = `${campaign.campaignUrl}/${key.split('/')[key.split('/').length - 1]}`;
        // console.log('New URL: ' + newUrl);
        items += `
                <url>
                  <loc>${`${finalHost}${newUrl}`}</loc>
                  <lastmod>${date}</lastmod>
                  <changefreq>weekly</changefreq>
                  <priority>0.5</priority>
                </url>
              `;
      });
    });
  }

  return `
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
       ${items}
      </urlset>
   `;
};

export const apiElements = [
  'category',
  'url',
  'summary__title',
  'summary__description',
  'tags__sector',
  'tags__theme_topic',
  'tags__content_type',
  'part_of_campaign_page',
];

const byCampaign = async (locale: string, campaignCodename: string) => {
  const response = await ProdClient.items<ContentPage>()
    .type('content_page')
    .emptyFilter('elements.metadata__exclude_from_sitemap_xml')
    .containsFilter('elements.part_of_campaign_page', [campaignCodename])
    .elementsParameter(apiElements)
    .languageParameter(locale)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise();
  return response.data.items;
};

const all = async (locale: string) => {
  const response = await ProdClient.items<ContentPage>()
    .type('content_page')
    .emptyFilter('elements.metadata__exclude_from_sitemap_xml')
    .notEmptyFilter('elements.url')
    .elementsParameter(apiElements)
    .languageParameter(locale)
    .equalsFilter('system.language', locale)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise();
  return response.data.items;
};
const byCodename = async (locale: string, codename: string, fullModel = true, category: string | undefined) => {
  let depth = DEPTH_PARAMETER;
  if (
    codename == 'dev___decision_tree_testing' ||
    codename == 'decarbonisation_campaign' ||
    codename == 'management_structure_of_ramboll_group'
  ) {
    depth = 5;
  } else if (category == 'annual_report') {
    depth = 3;
  }

  const response = fullModel
    ? await ProdClient.item<ContentPage>(codename).depthParameter(depth).languageParameter(locale).toPromise()
    : await ProdClient.item<ContentPage>(codename)
        .depthParameter(depth)
        .elementsParameter(apiElements)
        .languageParameter(locale)
        .toPromise()
        .catch(() => null);
  return response?.data ?? null;
};
const bySlug = async (locale: string, slug: string, fullModel = true, fullResponse: boolean) => {
  const page = await ProdClient.items<ContentPage>()
    .type('content_page')
    .languageParameter(locale)
    .equalsFilter('elements.url', slug)
    .equalsFilter('system.language', locale)
    .limitParameter(1)
    .elementsParameter(['category'])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => response.data.items[0]);

  let response = {} as any;
  if (page?.elements.category.value[0]?.codename === 'custom_form_page') {
    response = await ProdClient.item<ContentPage>(page.system.codename)
      .languageParameter(locale)
      .depthParameter(7)
      .toPromise()
      .then((response) => response.data.item);
  } else
    response = page
      ? await byCodename(locale, page.system.codename, fullModel, page?.elements.category.value[0]?.codename).then(
          (response) => (response ? (fullResponse ? response : response.item) : null),
        )
      : null;
  return response;
};
const byCategory = async (locale: string, category: string) => {
  const response = await ProdClient.items<ContentPage>()
    .type('content_page')
    .depthParameter(DEPTH_PARAMETER)
    .containsFilter('elements.category', [category])
    .languageParameter(locale)
    .equalsFilter('system.language', locale)
    .elementsParameter(apiElements)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise();
  return response.data.items;
};

const allLocales = async (codename: string) => {
  const keyValues = await Promise.all(
    distinctLangaugeVariants.map(async (languageCodename) => {
      const translations = await getTranslations(languageCodename);
      const slug = await ProdClient.item<ContentPage>(codename)
        .languageParameter(languageCodename)
        .elementsParameter(apiElements)
        .toPromise()
        .then((response) =>
          response.data.item.system.language == languageCodename
            ? Object.keys(normalize(response.data.item, undefined, undefined, true, translations))[0]
            : '',
        )
        .catch(() => '');

      return [languageCodename, slug];
    }),
  );

  return keyValues.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {} as { [key: string]: string });
};

const byId = async (locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!, id: string) => {
  return await ProdClient.items<ContentPage>()
    .type('content_page')
    .limitParameter(1)
    .depthParameter(DEPTH_PARAMETER)
    .elementsParameter(apiElements)
    .languageParameter(locale)
    .equalsFilter('system.id', id)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => res.data.items[0]);
};
export const GetContentPage = {
  all,
  byCategory,
  byCodename,
  bySlug,
  byCampaign,
  allLocales,
  byId,
};

const model = async (locale: string) => {
  const response = await ProdClient.item<Homepage>('homepage').languageParameter(locale).depthParameter(2).toPromise();
  return response.data.item;
};

export const getHomepage = {
  model,
};
