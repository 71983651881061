// import { isProduction } from '@/constants/general';
// import { ArticleRequestType } from '@/pages/api/articles';
// import { EmployeeRequestType } from '@/pages/api/employees';
// import { JobsRequestType } from '@/pages/api/jobs';
// import { ProjectsRequestType } from '@/pages/api/projects';
import Redis, { RedisOptions } from 'ioredis';

import { RedirectsRequestType } from '../../pages/api/redirect';
import configuration from './configuration';

// cache data setting an expiry of 10 mins
// this means that the cached data will remain alive for 10 minutes
// after that, we'll get fresh data from the DB
export const MAX_AGE = 60_000 * (process.env.NEXT_PUBLIC_APP_ENV == 'production' ? 10 : 0.085); // 10 mins for production and 5s for dev/qa
export const REDIRECT_EXPIRY = 60_000 * (process.env.NEXT_PUBLIC_APP_ENV == 'production' ? 60 * 24 : 0.085); // 24 hours for production and 5s for dev/qa
export const EXPIRY_MS = `PX`; // milliseconds
function getRedisConfiguration(): {
  port: string | undefined;
  host: string | undefined;
  password: string | undefined;
} {
  return configuration;
}

export function buildKey(query: (Partial<RedirectsRequestType> & { type?: string }) | string) {
  return JSON.stringify(query);
}

export async function createRedisInstance(config = getRedisConfiguration()) {
  console.log('creating redis instance...');
  try {
    const useTLS = Number(config.port) === 6380;
    const options: RedisOptions = {
      host: config.host,
      lazyConnect: true,
      tls: useTLS as any,
      showFriendlyErrorStack: true,
      enableAutoPipelining: true,
      keyPrefix: `${process.env.NEXT_PUBLIC_APP_ENV}:`,
      connectTimeout: 500,
      maxRetriesPerRequest: 3,
      retryStrategy: (times: number) => {
        if (times > 2) {
          console.warn(`[Redis] Could not connect after ${times} attempts`);
          return null;
        }
        return times;
      },
    };

    if (config.port) {
      options.port = Number(config.port);
    }

    if (config.password) {
      options.password = config.password;
    }
    // const test = new Redis.createClient('ramboll-dev.redis.cache.windows.net', 6379)
    const redis = new Redis(options);

    redis.on('error', (error: unknown) => {
      console.warn('[Redis] Error connecting', error);
    });

    // Test the connection. If it fails, we return null and redis is not being used.
    return await redis
      .call('PING')
      .then(() => redis)
      .catch(() => null);
  } catch (e) {
    console.error(`[Redis] Could not create a Redis instance`);
    return null;
  }
}
